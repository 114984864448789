import Vue from 'vue'
import BaseLayout from './components/customer/BaseLayout.vue';
import vuetify from './plugins/vuetify'
import router from './customer-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.config.productionTip = false

const moment = require('moment')
require('moment/locale/pl')
 
Vue.use(require('vue-moment'), {
    moment
});

Vue.use(require('vue-moment'));

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
    position: 'bottom-right'
});

import DynamicModal from './plugins/DynamicModal/ModalPlugin';
Vue.use(DynamicModal);

Vue.use(VueAxios, axios);

import Nl2br from 'vue-nl2br'
Vue.component('nl2br', Nl2br);

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
Vue.filter('money', function (value) {
    return moneyFormat.format(value);
})

if (process.env.VUE_APP_GTM) {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + process.env.VUE_APP_GTM);
    document.head.appendChild(tag);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments); }
    window.gtag('js', new Date());
    window.gtag('config', process.env.VUE_APP_GTM);
}

Vue.prototype.$copyToClipboard = function(text, notify) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    if (notify && this.$toast) {
        this.$toast.info("Skopiowano do schowka");
    }
}

new Vue({
    router,
    vuetify,
    render: h => h(BaseLayout)
}).$mount('#app')
